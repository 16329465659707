import Rest from '../Rest'

export default class OrderService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/orders'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  payOrder(orderId: Number, data: any) {
    return this.post(`${orderId}/pay`, data).then(
      (data) => {
        return data
      }
    )
  }
}
