<template>
  <div>
    <vs-tabs :key="tabsCorporateSalesKey">
      <vs-tab :label="$t('marketing_place.purchase_summary')">
        <CartResume :cartItems="cart.items"/>
          <div class="w-full flex gap-2 justify-end">
            <vs-button
              type="border"
              @click="goBack">
              {{ $t('common.back') }}
            </vs-button>
          </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import CartResume from '../store/CartResume'

import OrderService from '@/services/api/OrderService'

import { currencyFormatter } from '@/util/Util'

export default {
  components: {
    CartResume
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    cart: {
      items: []
    },
    //Services
    service: null,
  }),
  methods: {
    loadData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          if(response){
            this.cart = response
          }
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => {this.$vs.loading.close()})
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    goBack() {
      this.$router.push('/orders')
    },
  },
  beforeMount() {
    this.service = OrderService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
}
</script>
